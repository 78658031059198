import { default as abuseReportsdDlhA7STVQMeta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_93BhrKRaYVWwMeta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/admin/audit-log/[username]/[id].vue?macro=true";
import { default as indexEXCIPHMxbqMeta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/admin/index.vue?macro=true";
import { default as moderationjDQhfyKbgGMeta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/admin/moderation.vue?macro=true";
import { default as pendingBansPfTCTCmJ19Meta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/admin/pendingBans.vue?macro=true";
import { default as profilesKx2D6aLfdxMeta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/admin/profiles.vue?macro=true";
import { default as expensesr04rwkL2w8Meta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/admin/timesheets/expenses.vue?macro=true";
import { default as indexcXlYXL6wBlMeta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/admin/timesheets/index.vue?macro=true";
import { default as overviewoifq2w2TaDMeta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaitingrVa546ZXh4Meta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/admin/translations/awaiting.vue?macro=true";
import { default as missingtBOs4T237IMeta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/admin/translations/missing.vue?macro=true";
import { default as usersmO8Rc9VHGpMeta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/admin/users.vue?macro=true";
import { default as apiGnwYpo2kX7Meta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/api.vue?macro=true";
import { default as _91slug_939qJdHF1RRrMeta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/blog/[slug].vue?macro=true";
import { default as indexB1kI2h9dHLMeta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_93uE0upexMHeMeta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_93MIJVK4n1lwMeta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as adminn5DCbNkY2gMeta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/census/admin.vue?macro=true";
import { default as indexuYbVtzFkN9Meta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/census/index.vue?macro=true";
import { default as contactWTIGpWixsiMeta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/contact.vue?macro=true";
import { default as designs3k8mopzWIMeta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/design.vue?macro=true";
import { default as englishqGABl5Qs8tMeta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/english.vue?macro=true";
import { default as faq1YIDdIs8YJMeta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/faq.vue?macro=true";
import { default as inclusivetmhxy4SYKuMeta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/inclusive.vue?macro=true";
import { default as indexrue9t3hredMeta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/index.vue?macro=true";
import { default as license9xam91XedPMeta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/license.vue?macro=true";
import { default as academicXQXX0WHgJlMeta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/links/academic.vue?macro=true";
import { default as indexPhVH0yMLklMeta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/links/index.vue?macro=true";
import { default as mediapclVdCZja1Meta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/links/media.vue?macro=true";
import { default as translinguistics72HqfLpsFrMeta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/links/translinguistics.vue?macro=true";
import { default as zinefHFimfyNH2Meta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/links/zine.vue?macro=true";
import { default as namesRQQPnSu5oGMeta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/names.vue?macro=true";
import { default as indexYbi37FSqkpMeta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/nouns/index.vue?macro=true";
import { default as templatesGUWjzDCWu8Meta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/nouns/templates.vue?macro=true";
import { default as peopleTaCsWWUjmKMeta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/people.vue?macro=true";
import { default as privacyBShwHjeOBUMeta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/privacy.vue?macro=true";
import { default as _91username_93cwgztGcwI7Meta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_93CUHhRmVsdyMeta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/profile/card-[username].vue?macro=true";
import { default as editorCCFt6Tb3vXMeta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/profile/editor.vue?macro=true";
import { default as anyfpHiMewWvcMeta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/pronouns/any.vue?macro=true";
import { default as ask7rlApxHOPDMeta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/pronouns/ask.vue?macro=true";
import { default as avoidinggN0z89uzeRMeta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/pronouns/avoiding.vue?macro=true";
import { default as indexd7QmXoAyN0Meta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/pronouns/index.vue?macro=true";
import { default as mirrorvKDWv0Ii52Meta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/pronouns/mirror.vue?macro=true";
import { default as pronounamo2CpFMQeMeta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/pronouns/pronoun.vue?macro=true";
import { default as sourcesCQDDMR2L7xMeta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/sources.vue?macro=true";
import { default as teami9Js6NvCeMMeta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/team.vue?macro=true";
import { default as terminologyk1JKq6JHV4Meta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/terminology.vue?macro=true";
import { default as termsU3x4Ccq2NhMeta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/terms.vue?macro=true";
import { default as userTMilmdEpGpMeta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/user.vue?macro=true";
import { default as workshopsvPhqDK4bLnMeta } from "/home/admin/www/pronoms.fr/release/20241214161223/pages/workshops.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReportsdDlhA7STVQMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/admin/abuseReports.vue")
  },
  {
    name: "admin-audit-log-username-id",
    path: "/admin/audit-log/:username()/:id()",
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/admin/audit-log/[username]/[id].vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/admin/index.vue")
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/admin/moderation.vue")
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBansPfTCTCmJ19Meta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/admin/pendingBans.vue")
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/admin/profiles.vue")
  },
  {
    name: "admin-timesheets-expenses",
    path: "/admin/timesheets/expenses",
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/admin/timesheets/expenses.vue")
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/admin/timesheets/index.vue")
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/admin/timesheets/overview.vue")
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/admin/translations/awaiting.vue")
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/admin/translations/missing.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/admin/users.vue")
  },
  {
    name: "api",
    path: "/api",
    meta: apiGnwYpo2kX7Meta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/api.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_939qJdHF1RRrMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexB1kI2h9dHLMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/blog/index.vue")
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_93uE0upexMHeMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/calendar/[[year]].vue")
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_93MIJVK4n1lwMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/calendar/[year]-[month]-[day].vue")
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: adminn5DCbNkY2gMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/census/admin.vue")
  },
  {
    name: "census",
    path: "/census",
    meta: indexuYbVtzFkN9Meta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/census/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactWTIGpWixsiMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/contact.vue")
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/design.vue")
  },
  {
    name: "english",
    path: "/english",
    meta: englishqGABl5Qs8tMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/english.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faq1YIDdIs8YJMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/faq.vue")
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusivetmhxy4SYKuMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/inclusive.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/index.vue")
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/license.vue")
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academicXQXX0WHgJlMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/links/academic.vue")
  },
  {
    name: "links",
    path: "/links",
    meta: indexPhVH0yMLklMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/links/index.vue")
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: mediapclVdCZja1Meta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/links/media.vue")
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguistics72HqfLpsFrMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/links/translinguistics.vue")
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zinefHFimfyNH2Meta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/links/zine.vue")
  },
  {
    name: "names",
    path: "/names",
    meta: namesRQQPnSu5oGMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/names.vue")
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: indexYbi37FSqkpMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/nouns/index.vue")
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templatesGUWjzDCWu8Meta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/nouns/templates.vue")
  },
  {
    name: "people",
    path: "/people",
    meta: peopleTaCsWWUjmKMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/people.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyBShwHjeOBUMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/privacy.vue")
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_93cwgztGcwI7Meta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/profile/[username].vue")
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_93CUHhRmVsdyMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/profile/card-[username].vue")
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editorCCFt6Tb3vXMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/profile/editor.vue")
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: anyfpHiMewWvcMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/pronouns/any.vue")
  },
  {
    name: "pronouns-ask",
    path: "/pronouns/ask",
    meta: ask7rlApxHOPDMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/pronouns/ask.vue")
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoidinggN0z89uzeRMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/pronouns/avoiding.vue")
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: indexd7QmXoAyN0Meta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/pronouns/index.vue")
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirrorvKDWv0Ii52Meta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/pronouns/mirror.vue")
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronounamo2CpFMQeMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/pronouns/pronoun.vue")
  },
  {
    name: "sources",
    path: "/sources",
    meta: sourcesCQDDMR2L7xMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/sources.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: teami9Js6NvCeMMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/team.vue")
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminologyk1JKq6JHV4Meta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/terminology.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsU3x4Ccq2NhMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/terms.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: userTMilmdEpGpMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/user.vue")
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshopsvPhqDK4bLnMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241214161223/pages/workshops.vue")
  }
]